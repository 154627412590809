.container {
    position: relative;
    text-align: center;
    color: white;
}

.ContentColor {
    width: 100%;
    height: 100%;
    background-color: #0186ce81;
    position: absolute;
    top: 0%;
    left: 0%;
}

.iHeaderBtn {
    top: 55px;
    left: 60px;
    line-height: 45px;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    font-size: 3em;
    color: White;
}

.RecrutHeader {
    width: 430px;
    position: absolute;
    top: 20%;
    left: 38%;
    transform: translate(-50%, -50%);
    line-height: 45px;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    font-size: 3em;
    color: White;
}

.RecrutSubHeader {
    width: 430px;
    position: absolute;
    top: 30%;
    left: 38%;
    transform: translate(-50%, -50%);
    line-height: 45px;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    font-size: 1em;
    color: White;
}

.TextBtn {
    position: absolute;
    top: 77%;
    left: 18%;
    transform: translate(-50%, -50%);
    line-height: 30px;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    font-size: 1.8em;
    color: White;
}

.RecrutBody {
    width: 430px;
    position: absolute;
    top: 48%;
    left: 38%;
    transform: translate(-50%, -50%);
    line-height: 30px;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    font-size: 1.8em;
    color: White;
}

.iBodyBtn {
    margin-top: 24px;
    top: 115px;
    left: 60px;
    text-align: left;
    color: White;
}

.TextBGGGtn {
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.2em;
    padding: 10px;
    color: White;
}


.ContatctHeaderBtn {
    width: 430px;
    line-height: 35px;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    font-size: 2.3em;
    margin-bottom: 20px;
    display: "block";
    color: White;
}

.ContatctBodyBtn {
    width: 430px;
    margin-top: 10px;
    line-height: 1.1em;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    font-size: 1.48em;
    display: "block";
    color: White;
}

.ContatctRecruitDetail {
    margin-top: 10px;
    margin-left: 15px;
    line-height: 1.1em;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 200;
    font-size: 1.2em;
    display: "block";
    color: White;
}

.ContatctServiceGalleryTextBtn {
    height: 50px;
    margin-top: 30px;
    line-height: 20px;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    font-size: 1.5em;
    display: "block";
    color: White;
}