.FooterText {
    width: 500px;
    line-height: 25px;
    font-family: 'Kanit', sans-serif;
    align-items: center;
    justify-content: center;
    display: inline-block;
    font-size: 1.2em;
    color: White;
}

.BlueLine {
    height: 5px;
    width: 100%;
    background-color: #0187CE;
}