.Gallery-Header {
  margin-top: 80px;
  line-height: 35px;
  text-align: left;
  font-family: 'Kanit', sans-serif;
  font-weight: 500;
  font-size: 3.2em;
  color: black;
  padding: "8px 50px"
}

.Gallery-Body {
  margin-bottom: 30px;
  line-height: 35px;
  text-align: left;
  font-family: 'Kanit', sans-serif;
  font-weight: 300;
  font-size: 1.5em;
  color: black;
}


.galleryArrow {
  font-size: 35px;
  color: #24442F;
  cursor: pointer;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: currentColor;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  left: 10px;
  z-index: 2;
  font-size: 35px;
  color: #24442F;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  right: 10px;
  z-index: 2;
  font-size: 35px;
  color: #24442F;
}

.ant-carousel .slick-dots li {
  width: 8px !important;
  height: 8px !important;
}

.ant-carousel .slick-dots li button {
  background-color: #B5BFB9 !important;
  height: 8px !important;
  border-radius: 4px !important;
}

.ant-carousel .slick-dots li.slick-active {
  background-color: #24442F !important;
  height: 8px !important;
  border-radius: 4px !important;
}

ul {
  margin-bottom: -3em !important;
}

.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.ant-image-preview-operations {
  background: rgba(0, 0, 0, 0.4) !important;
}