.AboutUs {
    padding-top: 80px;
}

.AboutUs-Header {
    line-height: 1.2em;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    font-size: 3.5em;
    color: #464749;
}

.AboutUs-SubHeader {
    line-height: 1.2em;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 600;
    font-size: 1.5em;
    color: #464749;
}

.AboutUs-Body {
    font-family: 'Kanit', sans-serif;
    font-size: 1.25em;
    line-height: 1.35em;
    font-weight: 300;
    color: #464749;
    text-align: justify;
}

.AboutUs-BtnText {
    color: white;
    font-family: RSU Regular;
    font-size: 18px;
}

.MapLocation-Btn {
    display: flex;
    align-items: center;
    justify-content: end;
}