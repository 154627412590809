.container {
    position: relative;
    text-align: center;
    color: white;
}

.noRepeatImage {
    background-repeat: repeat-y;
}

.OurServiceHeaderBtn {
    width: 430px;
    margin-top: 30px;
    margin-left: 60px;
    line-height: 35px;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    font-size: 2.4em;
    color: White;
}

.OurServiceBodyBtn {
    width: 430px;
    margin-top: 10px;
    margin-left: 60px;
    line-height: 20px;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    color: White;
}



.Header {
    padding-top: 5px;
    line-height: 0.8em;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 600;
    font-size: 1.8em;
    color: #464749;
}

.Buttom {
    text-align: center;
    height: 80px;
    line-height: 1em;
    background-color: rgba(65, 65, 65, 0.8);
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    font-size: 2em;
    color: white;
    position: absolute !important;
    z-index: 10;
    bottom: 0px;
    left: 0;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.SubHeader {
    padding-top: 8px;
    line-height: 1em;
    height: 50px;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    font-size: 1.1em;
    color: #464749;
}

.Content {
    padding-top: 20px;
    height: 100px;
    overflow: hidden;
    line-height: 20px;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    font-size: 1em;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;
    color: #464749;
}

.partialcontent {
    margin-top: 10px;
    /* max-lines: 4; */
    display: -webkit-box;
    padding-right: 10px;
    font-family: 'Kanit', sans-serif;
    font-weight: 300;
    line-height: 1.3em;
    text-align: justify;
    -webkit-box-orient: vertical;
    /* -webkit-line-clamp: 4; */
    color: #464749;
    overflow: hidden;
    /* text-overflow: ellipsis; */
}