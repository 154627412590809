.container {
    position: relative;
    text-align: center;
    color: white;
}


.HeaderBtn {
    width: 430px;
    line-height: 35px;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    font-size: 2.5em;
    color: White;
}

.BodyBtn {
    max-width: 430px;
    margin-top: 10px;
    line-height: 1.1em;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    font-size: 1.5em;
    color: White;
}

.ServiceGalleryTextBtn {
    height: 50px;
    margin-top: 30px;
    line-height: 20px;
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    font-size: 1.5em;
    color: White;
}