.OurPartner-Header {
    margin-top: 80px;
    line-height: 35px;
    text-align: center;
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    font-size: 3.2em;
    color: black;
    padding: "8px 50px"
}

.OurPartner-Body {
    margin-bottom: 18px;
    line-height: 35px;
    text-align: center;
    font-family: 'Kanit', sans-serif;
    font-weight: 300;
    font-size: 1.5em;
    color: black;
}