.Header {
    text-align: center;
  }

.Header-menu {
  align-items: center;
  justify-content: center;
}

.Header-text {
  font-family: Kanit !important;
  color:white;
}

.Header-Btn{
  color: white;
  font-family: Kanit !important;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

a:hover {
  background-color: yellow;
}

.ant-drawer-header{
  margin-top: 20px !important;

}